import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import ConfettiGenerator from "confetti-js";
const items = [
  {
    id: 1,
    name: "Stor flamingo",
    price: 2000,
    image: "https://i.imgur.com/LF9nfrL.png",
  },
  {
    id: 2,
    name: "Verktyg när man cyklat in i väggen",
    price: 1000,
    image: "https://i.imgur.com/wsWh2VX.png",
    svg: "tools",
  },
  {
    id: 3,
    name: "Knallsnören, bara på lördagar",
    price: 50,
    image: "https://i.imgur.com/cjZjKBV.png",
    svg: "candy",
  },
  {
    id: 5,
    name: "Fridlyst Orkidé",
    price: 98.6,
    image: "https://i.imgur.com/DbVyOPk.png",
    svg: "flower",
  },

  {
    id: 6,
    name: "Kopp med glad man i rolig hatt",
    price: 111.1,
    image: "https://i.imgur.com/CgXSzo7.png",
    svg: "bev",
  },
  {
    id: 7,
    name: "Micke, leksaksavbildning",
    price: 170,
    image: "https://i.imgur.com/yzz0Se5.png",
    svg: "superman",
  },

  {
    id: 8,
    name: "Kaffekopp, sportlag (?)",
    price: -25,
    image: "https://i.imgur.com/1s9u9vb.png",
    svg: "bev",
  },
  {
    id: 9,
    name: "Modernt grenuttag, sladdar ingår ej",
    price: 100,
    image: "https://i.imgur.com/WVnhL8J.png",
    svg: "battery",
  },
  {
    id: 10,
    name: "Gult bär",
    price: 12,
    image: "https://i.imgur.com/HNYmUPd.png",
    svg: "banana",
  },
  {
    id: 12,
    name: "eple",
    price: 10,
    image: "https://i.imgur.com/6ARCi1Y.png",
    svg: "apple",
  },
  {
    id: 13,
    name: "Portabel kaffemugg, påklistrat sportmotiv (?)",
    price: -1,
    image: "https://i.imgur.com/O0x813E.png",
    svg: "bev",
  },
  {
    id: 14,
    name: "Rolig skärmtomte med låååååååångt skägg",
    price: 200,
    image: "https://i.imgur.com/ER4EsCF.png",
    svg: "santa",
  },
  {
    id: 15,
    name: "Flamingopenna, fluffig",
    price: 80,
    image: "https://i.imgur.com/4CEXs9R.png",
  },

  {
    id: 16,
    name: "Flamingsugrör, papprig",
    price: 60,
    image: "https://i.imgur.com/vLEWpjv.png",
  },
  {
    id: 17,
    name: "Batteri (tyvärr testat med tungan)",
    price: 25,
    image: "https://i.imgur.com/QqMhaWf.png",
    svg: "battery",
  },

  {
    id: 18,
    name: "Hörlurar, skitsnacks-cancelling",
    price: 1337,
    image: "https://i.imgur.com/RWki0ZI.png",
    svg: "phones",
  },
  {
    id: 19,
    name: "Backspegel för skärm, stilig herre ingår ej",
    price: 99.9,
    image: "https://i.imgur.com/cVclBZT.png",
    svg: "mirror",
  },
  {
    id: 20,
    name: "Lapp",
    price: 1000,
    image: "https://i.imgur.com/lRoHBNL.png",
    svg: "paper",
  },

  {
    id: 21,
    name: "Balansgrej, knarrar om vikt > 50kg",
    price: 200,
    image: "https://i.imgur.com/n4m8Rzn.png",
    svg: "board",
  },
  {
    id: 22,
    name: "Flaska med hemgjord glögg, innehåll tyvärr slut 😢",
    price: 79.9,
    image: "https://i.imgur.com/PUlCJrF.png",
    svg: "wine",
  },
];

const buyoutText = [
  "Tack för att du valde Mickes loppis!",
  "Vi hoppas att du kommer att få mycket glädje av det du har köpt. Vi har haft kul iaf",
  "Om du har några frågor eller behöver hjälp med något, tveka inte att höra av dig till oss på micke@🍻.se.",
  "Vi hoppas att du kommer att besöka oss varje dag i resten av ditt liv!",
  "Om du har några problem med det du har köpt, får du väl försöka lösa dem själv.",
  "Tack för dina pengar",
  "Vill du göra en retur så har vi tyvärr stängt 😢",
];

const slogans = [
  "Den perfekta platsen för att hitta saker du inte vill ha!",
  "Vi har allt du aldrig kommer att använda!",
  "Allt du aldrig kommer att använda, men ändå inte kan motstå att köpa!",
  "Här kan du hitta saker du inte ens visste fanns!",
  "Allt du vill ha, och absolut inget mer!",
];

function Item({ item, onBuy }) {
  return (
    <div className="item">
      <img src={item.image} alt={item.name} />
      <h3>{item.name}</h3>
      <p>Pris: {item.price} kr</p>
      <button onClick={onBuy}>Lägg till i 🧺</button>
    </div>
  );
}

function ShoppingCart({ items, onCheckout }) {
  return (
    <div className="shopping-cart">
      <h2>Varukorg</h2>
      {items.map((item) => (
        <div key={item.id}>
          {item.count > 1 && `(${item.count})`} {item.name} - {item.price} kr
        </div>
      ))}

      {items.length > 0 && (
        <>
          <p>
            Totalt:{" "}
            {items.reduce(
              (total, item) =>
                total + (item.count ? item.count * item.price : item.price),
              0
            )}{" "}
            kr
          </p>
          <button onClick={onCheckout}>Slå till</button>
          <p>
            {items.filter(({id}) => ([1, 15, 16].includes(id))).length === 3 ? <span style={{color: 'hotpink'}}>Flabingo!</span> : ''}
          </p>
        </>
      )}
    </div>
  );
}

function App() {
  const [cart, setCart] = useState([]);
  const ref = useRef(null);
  // const [, setIsExploding] = useState(false);

  useEffect(() => {
    const confettiSettings = {
      target: "my-canvas",
      max: "100",
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
      props: [
        { type: "svg", src: "./flamingo.svg", size: 50, weight: null },
        ...cart
          .filter((elem) => elem.svg)
          .map((elem) => {
            return {
              type: "svg",
              src: `./${elem.svg}.svg`,
              size: 50,
              weight: null,
            };
          }),
      ],
      // start_from_edge: true,
      clock: "50",
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, [cart]);

  const handleBuy = (item) => {
    const tempItem = item;
    const ind = cart.findIndex((elem) => elem.id === item.id);
    let tempAry = [];
    if (ind !== -1) {
      tempAry = [...cart];
      tempAry[ind].count = tempAry[ind].count + 1;
    } else {
      tempItem.count = 1;
      tempAry = [...cart];
      tempAry.push(tempItem);
    }
    setCart(tempAry);
  };

  const handleCheckout = () => {
    alert(buyoutText[Math.floor(Math.random() * buyoutText.length)]);
    setCart([]);
  };
  return (
    <div>
      <div className="layout">
        <div className="store" ref={ref}>
          <h1>Mickes loppis</h1>
          <h4>{slogans[Math.floor(Math.random() * slogans.length)]}</h4>
          {cart.length > 0 && (
            <ShoppingCart items={cart} onCheckout={handleCheckout} />
          )}
          <div className="item-list">
            {items.map((item) => (
              <Item key={item.id} item={item} onBuy={() => handleBuy(item)} />
            ))}
          </div>
        </div>
      </div>
      <canvas id="my-canvas"></canvas>
    </div>
  );
}

export default App;
